import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import Layout from '../components/layout';
import Yoast from '../components/Yoast';

function Contact({ pageContext }) {
  const wpPage = pageContext.wpPage;
  return (
    <Layout hasRecentWork={false} hasTestimonials={false}>
      <Yoast title={wpPage.yoast_title} meta={wpPage.yoast_meta} />
      <div className="template--contact">
        <Container>
          <h1 className="page-title">Contact Us</h1>
          <div className="d-flex flex-column-reverse flex-lg-row mb-4">
            <div style={{ flexBasis: `50%` }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2968.347021376957!2d-87.83022318416056!3d41.92839397921833!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fcaee2d19715b%3A0x9cf8f5fae490e5f1!2s2655%20Davisson%20St%2C%20River%20Grove%2C%20IL%2060171!5e0!3m2!1sen!2sus!4v1576866632090!5m2!1sen!2sus"
                width="100%"
                height="450"
                frameBorder="0"
                style={{ border: 0, maxWidth: `600px` }}
                allowFullScreen=""
                className="mx-auto d-block"
              ></iframe>
            </div>
            <div className="pl-lg-5 text-center text-lg-left">
              <h4>Mad Masonry</h4>
              <address>
                2655 Davisson St
                <br />
                River Grove, IL 60171
              </address>
              <p>(708) 716-3495</p>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
}

Contact.propTypes = {
  children: PropTypes.any,
  pageContext: PropTypes.object,
};

export default Contact;
